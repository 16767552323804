"use strict";

$(
    function () {
		app.initializeMessages('_Upload_Incorrect_File,_Incorrect_Email,_You_Did_Not_Fill_Input,_Check_Least_One_Item,_Select_One_Item,_Please_Select_Payment_Method,_You_Did_Attach_File');
		var $form = $('.cart-form form');
		if ($form.length === 1) {
			app.forms.syncForm($form);
        }
	}
);

app.forms = {
    _vars: {
        v: 1,
        metaData: {

        },
        elementsMetaData: {

        }
    },
    initRegForm: function() {
        var $form = $('form.user-register');
        if ($form.length === 0) {
            return false;
        }
        $form.find('tr.field-description').each(
            function () {
                var $this = $(this);
                var $td = $this.find('td');
                var html = $td.html();
                var data = null;
                try {
                    data = JSON.parse(html);
                    if (data.description) {
                        $td.html(data.description);
                        $this.addClass('_done');
                    }
                    var $trNext = $this.next('tr');
                    var $inp = $trNext.find('textarea, input, select');
                    if ($inp.length > 0) {
                        $inp.each(
                            function() {
                                var name = $(this).attr('name');
                                if (name) {
                                    app.forms._vars.metaData[name.replace('[]', '')] = data;
                                }
                            }
                        )
                    }
                } catch (e) {
                    $this.addClass('_done');
                }
            }
        );

        app.forms.init($form);
    },
    initSvgFields: function() {
        var $form = $('.main-svg-wrapper .svg-fields');

       $form.find('input, select, textarea').each(
           function() {
               var $this = $(this);
               var id = $this.attr('id');
               if (id && id.indexOf('_fld-') === 0) {
                   $this.attr('name', id.replace('_fld-', ''));
               }
           }
       );

       app.events.addListener(
           'udu-FORM-IS-UPDATED',
           function(e) {
               for (var i in app.forms._vars.metaData) {
                   svgEditor._applyToSvgItem($('#_fld-' + i));
               }
           }
       );

       app.forms.init($form);
    },
    init: function($form) {
        if (!$form) {
            return false;
        }
        this._vars.$lastForm = $form;
        if (app.forms._vars.metaData) {
            for (var i in app.forms._vars.metaData) {
                var hiddenItems = app.forms._vars.metaData[i].hiddenItems;
                if (hiddenItems && hiddenItems.length) {
                    for (var a = 0; a < hiddenItems.length; a++) {
                        var vl = hiddenItems[a].toLowerCase();
                        var sel = 'input[name="' + i + '[]"]';
                        try {
                            $form.find(sel).each(
                                function() {
                                    var $this = $(this);
                                    if ($this.val().toLowerCase() === vl) {
                                        $this.closest('li').remove();
                                    }
                                }
                            )
                        } catch(e) {
                            console.log('Error: ' + i);
                        }

                        sel = 'select[name="' + i + '"]';
                        try {
                            $form.find(sel).find('option').each(
                                function() {
                                    var $this = $(this);
                                    if ($this.val().toLowerCase() === vl) {
                                        $this.remove();
                                    }
                                }
                            )
                        } catch(e) {
                            console.log('Error: ' + i);
                        }
                    }
                }
            }
        }

        $form.find('.udu__clear-upload:not(._fri_done)')
			.addClass('_fri_done')
            .click(
            function() {
                var $div = $(this).closest('div.udu__frm-upload-wrapper').removeClass('udu__loaded');
                var $item = $div.find('input[type=file]').val('');
                $('.udu__hidden-upload-' + $item.attr('id')).remove();
            }
        );
        $form.find('input:not(._fri_done), select:not(._fri_done), textarea:not(._fri_done)')
            .addClass('_fri_done')
            .change(
            function() {
                var $this = $(this);
                if ($this.attr('type') === 'file') {
                    app.forms.processFile($this);
                }

                app.forms.syncForm($form, {$item: $this});
            }
        ).keyup(
            function() {
                app.forms.syncForm($form);
            }
        ).focus(
            function() {
                $(this).removeClass('invalid-item error udu__tr-invalid-item');
            }
        );
        if (app.prodExtData && app.prodExtData.productDataFields) {
            app.forms._vars.elementsMetaData = app.prodExtData.productDataFields;
        }

		app.assignNumberControl()
        app.forms.syncForm($form);
    },
    processFile: function($item) {
        if ($item.closest('.udu__mult-upload-wrapper').length > 0) {
            return false;
        }
        var file = $item[0];
        var reader = new FileReader();
        reader.onload = function(evt) {
            var fileString = evt.target.result;
            var $div = $item.closest('div.udu__frm-upload-wrapper').addClass('udu__loaded');
            var sysType = 'Image';
            if ($item.hasClass('udu__upl-image')) {
                $div.find('.udu__from-upload-item img').attr('src', fileString);
                sysType = 'Image';
            }

            if (app.utils.file.ext(file.files[0].name) === 'svg') {
                sysType = 'AnyFile';
            }

            var id = $item.attr('id');

            $item.closest('form').append('<input class="udu__hidden-upload-' + id + '" type="hidden" id="upl-file-systemType-' + id + '" value="' + sysType + '" name="attachments[' + id + '][systemType]">')
                .append('<input class="udu__hidden-upload-' + id + '" type="hidden" id="upl-file-contentType-' + id + '" value="' + file.files[0].type + '" name="attachments[' + id + '][contentType]">')
                .append('<input class="udu__hidden-upload-' + id + '" type="hidden" id="upl-file-title-' + id + '" value="' + file.files[0].name + '" name="attachments[' + id+ '][title]">')
                .append('<input class="udu__hidden-upload-' + id + '" type="hidden" id="upl-file-data-' + id + '" value="" name="attachments[' + id + '][data]">');

            $('#upl-file-data-' + id).val(fileString.split('base64,')[1]);
        };


        if ($item.hasClass('udu__upl-image')) {
            reader.readAsDataURL($item[0].files[0]);
        } else {
            reader.readAsText($item[0].files[0], "UTF-8");
        }
    },
    initForm: function($form, metaData) {
        if (!metaData) {
            if (app.prodExtData && app.prodExtData.productDataFields) {
                metaData = app.prodExtData.productDataFields;
            } else {
                metaData = {};
            }
        }
    },
    syncForm: function($form, params) {
        if (!params) {
            params = {

            };
        }
        var $items = $form.find('input, select, textarea');
        if (app.forms._vars.elementsMetaData && app.forms._vars.elementsMetaData.items) {
            var elemData = app.forms._vars.elementsMetaData;
            for (var i in elemData['items']) {
                var info = elemData['items'][i];

                if (!info.id) {
                    info.id = 'udu__fi-' + i;
                }
                var $el = $('#' + info.id);
                if ($el.length === 0) {
                    continue;
                }
                var dispRow = true;
                if (info.showIf) {
                    dispRow = false;
                    if (app.forms.itemValueIsThere(info.showIf)) {
                        dispRow = true;
                    }
                }

                if (dispRow && info.hideIf) {
                    if (app.forms.itemValueIsThere(info.hideIf)) {
                        dispRow = false;
                    }
                }

                var trDispChanged = false;
                var $tr = $el.closest('tr.fld-line');
                if (!dispRow) {
                    if (!$tr.hasClass('hidden')) {
                        trDispChanged = true;
                    }
                    $tr.addClass('hidden');
                } else {
                    if ($tr.hasClass('hidden')) {
                        trDispChanged = true;
                    }
                    $tr.removeClass('hidden');
                }

                if (trDispChanged) {
                    app.utils.effects.showIt($tr);
                }

                if (!info.options) {
                    continue;
                }

                var itemWasChange = false;

                for (var a in info.options) {
                    var opt = info.options[a];
                    var needDisplay = true;
                    if (opt.showIf) {
                        needDisplay = false;
                        if (app.forms.itemValueIsThere(opt.showIf)) {
                            needDisplay = true;
                        }
                    }
                    if (needDisplay && opt.hideIf) {
                        if (app.forms.itemValueIsThere(opt.hideIf)) {
                            needDisplay = false;
                        }
                    }

                    var $op = $el.find('option[data-opt-id="' + a + '"]');

                    if (needDisplay) {
                        if ($op.hasClass('hidden')) {
                            itemWasChange = true;
                        }
                        $op.removeClass('hidden');
                    } else {
                        if (!$op.hasClass('hidden')) {
                            itemWasChange = true;
                        }
                        $op.addClass('hidden');
                    }
                }

                if (itemWasChange) {
                    $el.val('');
                    app.utils.effects.pulseIt($el);
                    if (params.$item && params.$item.val() && !params.balloonWasShown) {
                        params.balloonWasShown = true;
                        app.balloon.showInfoByCode(
                            $el,
                            '_form_Select_Value_For_Item',
                            {multipleMode: true}
                         );
                    }
                }
            }
        }

        $form.find('.udu__form-tr-was-hidden').removeClass('hidden');

        $items.each(
            function() {
                var $this = $(this);
                var iniRequired = $this.data('udu-ini-req');
                if (!iniRequired) {
                    if ($this.hasClass('required') || $this.closest('tr').hasClass('required')) {
                        iniRequired = 'Y';
                    } else {
                        iniRequired = 'N';
                    }
                    $this.data('udu-ini-req', iniRequired)
                }
                app.forms.setItemReq($this, iniRequired === 'Y');
            }
        );
        $items.each(
            function() {
                var $this = $(this);
                var nm = $this.attr('name');
                var meta = app.forms._vars.metaData[nm];
                var $tr = $this.closest('tr, div.fld-block');
                if (meta) {
                    if (meta.reqIf) {
                        for (var i in meta.reqIf) {
                            if (app.forms.inArray(meta.reqIf[i], app.forms.getValByName(i, $form))) {
                                app.forms.setItemReq($this, true);
                                break;
                            }
                        }
                    }
                    if (meta.notReqIf) {
                        app.forms.setItemReq($this, true);
                        for (var i in meta.notReqIf) {
                            if (app.forms.inArray(meta.notReqIf[i], app.forms.getValByName(i, $form))) {
                                app.forms.setItemReq($this, false);
                                break;
                            }
                        }
                    }
                    if (meta.hideIf) {
                        for (var i in meta.hideIf) {
                            if (app.forms.inArray(meta.hideIf[i], app.forms.getValByName(i, $form))) {
                                app.forms.hideTr($tr, true);
                                break;
                            }
                        }
                    }
                    if (meta.showIf) {
                        $tr.addClass('hidden udu__form-tr-was-hidden');
                        for (var i in meta.showIf) {
                            if (app.forms.inArray(meta.showIf[i], app.forms.getValByName(i, $form))) {
                                app.forms.hideTr($tr, false);
                                break;
                            }
                        }
                    }
                }
            }
        );

        app.events.trigger(
            'udu-FORM-IS-UPDATED',
            {

            }
        );
    },
    hideTr: function($tr, needHide) {
        var $prevTr = $tr.prev('tr');
        if (!$prevTr.hasClass('field-description')) {
            $prevTr = null;
        }
        if (needHide) {
            $tr.addClass('hidden udu__form-tr-was-hidden');
            if ($prevTr) {
                $prevTr.addClass('hidden udu__form-tr-was-hidden');
            }
        } else {
            $tr.removeClass('hidden');
            if ($prevTr) {
                $prevTr.removeClass('hidden');
            }
        }

    },
    inArray: function(inpVal, Values) {
        if (typeof inpVal === 'string') {
            if (Values[inpVal.toLowerCase()]) {
                return true;
            }
        } else if (typeof inpVal === 'object') {
            for (var a in inpVal) {
                if (Values[String(inpVal[a]).toLowerCase()]) {
                    return true;
                }
            }
        }
        return false;
    },
    getValByName: function(nm, $form) {
        var $inp = $form.find('*[name=' + nm + ']');
        if ($inp.length === 0) {
            $inp = $form.find('*[name="' + nm + '[]"]');
        }
        if ($inp.attr('type') === 'radio') {
            $inp = $form.find('input[name="' + $inp.attr('name') + '"]:checked');
        }
       if ($inp.length === 0) {
            return {};
       }

       var notNull = false;
       var res = {};
        $inp.each(
            function() {
                var $this = $(this);
                var tp = $this.attr('type');
                if (tp === 'checkbox') {
                    if ($this.prop('checked')) {
                        res[$this.val().toLowerCase()] = true;
                        notNull = true;
                    }
                } else if ($this[0].tagName === 'SELECT') {
                    var $opt = $this.find('option:selected');
                    if ($opt.length > 0) {
                        notNull = true;
                        res[$opt.val().toLowerCase()] = true;
                        res[$opt.html().toLowerCase()] = true;
                    } else if ($this.val()) {
                        res[$this.val().toLowerCase()] = true;
                        notNull = true;
                    }
                } else if ($this.val()) {
                    res[$this.val().toLowerCase()] = true;
                    notNull = true;
                }
            }
        );

        if (notNull) {
            res['NOT_NULL'] = true;
        }

       return res;

    },
    fillFormWithSavedData: function($form, data) {
        if (typeof data === 'string') {
            data = app.getLData(data);
        }
        if (!Array.isArray(data)) {
            return this;
        }

		for (var i = 0; i < data.length; i++) {
            var nm = data[i].name;
            if (!nm) {
                continue;
            }
            $form.find('*[name="' + nm + '"]').val(data[i].value);
        }
    },
    itemValueIsThere: function(options) {
        for (var b in options) {
            var $pElem = $('#' + b);
            if ($pElem.length === 0 || !$pElem.is(':visible')) {
                continue;
            }

            if ($pElem[0].tagName === 'SELECT') {
                var elemValue = $pElem.find('option:selected').data('opt-id');
                if (!elemValue) {
                    elemValue = '';
                }
                return app.forms._inOptions(elemValue, options[b]);
            } else if ($pElem[0].tagName === 'INPUT' && $pElem.attr('type') === 'checkbox') {
                if ($pElem.prop('checked')) {
                    elemValue = $pElem.attr('value');
                } else {
                    elemValue = '';
                }
                return app.forms._inOptions(elemValue, options[b]);
            }

        }
        return false;
    },
    _inOptions: function(val, opts) {
        if (typeof opts === 'string') {
            opts = opts.split('|');
        }
        for (var c = 0; c < opts.length; c++) {
            if (String(val) === String(opts[c])) {
                return true;
            }
        }
    },
    setItemReq: function($item, req) {
        var $tr = $item.closest('tr, div.fld-block');
        if (req) {
            $item.addClass('required');
            $tr.addClass('required').find('td.label label, div.title, div.field').addClass('required');
        } else {
            $item.removeClass('required');
            $tr.removeClass('required').find('td.label label, div.title, div.field').removeClass('required');
        }
    },
    obj2formNamesItems: function(obj, path) {
        if (!path) {
            path = '';
            this._vars._obj2formNamesItems = {};
        }
        for (var property in obj) {
            if (obj.hasOwnProperty(property)) {
                if (typeof obj[property] == "object") {
                    this.obj2formNamesItems(obj[property], path + '[' + property + ']');
                }
                else {
                    this._vars._obj2formNamesItems[path + '[' + property + "]"] = obj[property];
                }
            }
        }
        return this._vars._obj2formNamesItems;
    },
    /**
     * Performes adding Inputs to form with specific name to post OBJECT to server part
     *
     * @param {object} obj
     * @param {string} baseName
     */
    appendObjectToFormAsItems: function(obj, baseName) {
        var $dataInput = $('input[name="' + baseName + '[data]"]');
        if ($dataInput.length === 0) {
            return false;
        }
        var $form = $dataInput.closest('form');
        $form.find('input[name*="' + baseName + '[extData]"]').remove();
        var items = this.obj2formNamesItems(obj);
        for (var i in items) {
            var parentId = String(i).split(']')[0].replace('[','');
            if (parentId) {
                var $parentItem = $form.find('#' + app.forms.fixId(parentId));
                if ($parentItem.length !== 1) {
					$parentItem = $form.find('tr[data-udu-id="' + parentId + '"] > td.label > div.dots');
					if ($parentItem.length !== 1) {
						$parentItem = null;
                    }
                }
            } else {
				$parentItem = null;
            }

			var id = 'inp_' + app.guid();
            var className = app.utils.str.md5(baseName);
            // we use this approach to put extData field under main field to not break the order of POST items
            var inpHtml = '<input class="' + className + '" type="hidden" id="' + id + '" name="' + baseName + '[extData]' + i + '">';
            if ($parentItem) {
				$parentItem.after(inpHtml);
            } else {
                var $tmp = $('.' + className).last();
                if ($tmp.length > 0) {
					$tmp.after(inpHtml);
                } else {
					$dataInput.after(inpHtml);
                }

            }
            $('#' + id).val(items[i]);
        }
    },
    fixId: function(id) {
        id = String(id)
            .replace(/\//g, '\\/')
            .replace(/\./g, '\\.')
            .replace(/\?/g, '\\?')
            .replace(/\!/g, '\\!')
            .replace(/\(/g, '\\(')
            .replace(/\)/g, '\\)')
            .replace(/\ /g, '\\ ')
            .replace(/\*/g, '\\*');



        return id;
    },
    escapeSelName: function(nm) {
        return nm.replace(/\[/g, '\\[').replace(/\]/g, '\\]');
    },
    checkEmail: function(inputvalue){
        if (!inputvalue) {
            return true;
        }
        var emailPattern = /^[a-zA-Z0-9._-]{2,}@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$/;
        return emailPattern.test(inputvalue);
    },
    getFormData: function(inForm) {
        var data = {};
        $(inForm).find('input:not(.udu__not-form-item), textarea:not(.udu__not-form-item), select:not(.udu__not-form-item)').each(
            function() {
                if (!data[this.name]) {
                    if ($(this).is(':radio')) {
                        data[this.name] = $('input[name="'+this.name+'"]:checked', inForm).val();
                    } else if ($(this).is(':file')) {

                    } else if ($(this).is(':checkbox')) {
                        data[this.name] = app.forms.getCheckBoxesValue(this.name);
                    } else {
                        data[this.name] = this.value;
                    }
                }
            }
        );
        return data;
    },
    getCheckBoxesValue: function(inName) {
        var str = "";
        var selector = 'input[name="' + this.escapeSelName(inName)  + '"]:checked';
        $(selector).each (
            function() {
                str += this.value + ', ';
            }
        );
        return str.substr(0, str.length - 2);
    },
    prepareFormForSubmit: function ($form) {
        $form = $($form);
        var $items = $form.find('input.udu__remove-blanks-before-post');
        $items.each(
            function() {
                var $this = $(this);
                var id = 'inp_' + app.guid();
                $form.append('<input type="hidden" id="' + id + '" class="udu__for-form-submit-purpose-only">');
                var $newItem = $('#' + id);
                $newItem.attr('name', $this.attr('name')).val($this.val().replace(/ /g, ''));
                if (!$this.data('udu-name')) {
                    $this.data('udu-name', $this.attr('name'));
                }
                $this.attr('name', '').addClass('udu__restore-item-after-submit');
            }
        );
        setTimeout(
            function() {
                $form.find('.udu__for-form-submit-purpose-only').remove();
                $form.find('.udu__restore-item-after-submit').each(
                    function() {
                        var $this = $(this);
                        $this.removeClass('udu__restore-item-after-submit').attr('name', $this.data('udu-name'));
                    }
                )
            },
            100
        )
    },
    checkForm: function(inForm, params) {
        params = params || {};
        var $form = $(inForm);

        if (window.tinyMCE) {
            $form.find("textarea.tinyimce").each (
                function () {
                    $(this).val(tinyMCE.get(this.id).getContent());
                }
            )
        }

        var $balItem = null;

        $form.find('.udu__shake, .invalid-item, .udu__tr-invalid-item')
            .removeClass('udu__shake invalid-item udu__tr-invalid-item')
            .data('udu__er-init', '');

        var arr = $form.find('input.required:not(.hidden):not(.udu__not-form-item), textarea.required:not(.udu__not-form-item), select.required:not(.udu__not-form-item)');
        if (params.addSelector) {
			arr = arr.add($(params.addSelector));
        }

        for (var x = 0; x < arr.length; x++) {
            var $item = $(arr[x]);
                $item.data('udu-before-submit-value', $item.val());
            if ($item.is(':radio')) {
                if (!$('input[name="' + this.escapeSelName(arr[x].name) + '"]:checked', inForm).val()) {
                    if (!$balItem) {
                        if (arr[x].name === 'paymentMethodId') {
                            var $tmpItem = $('li:not(.udu__disabled) input[name=paymentMethodId]');
                            if ($tmpItem.length > 0) {
                                $balItem = app.balloon.showErrorByCode(
                                    $tmpItem,
                                    '_Please_Select_Payment_Method'
                                );
                            }
                        } else {
                            if (!$item.prop('disabled')) {
                                $balItem = app.balloon.showErrorByCode(
                                    $item,
                                    '_Select_One_Item'
                                );
                            }
                        }
                    }
                }
            } else if ($item.hasClass('udu__multiple-input-file')) {
               var $place = $item.closest('.udu__mult-upload-wrapper');
               var $files = $place.find('div.item');
               if ($files.length === 0) {
				   $balItem = app.forms.markAsInvalid(
					   $place,
					   '_You_Did_Attach_File',
					   !$balItem
				   );
               }
            } else if ($item.is(':checkbox')) {
                if (!app.forms.getCheckBoxesValue(arr[x].name)) {
                    if (!$balItem) {
                        if ($item.hasClass('udu__order-attr-single-ch')) {
							$balItem = app.balloon.showErrorByCode(
								$item,
								'_Check_This_Item',
								{side:['top', 'bottom', 'right', 'left']}
							);
                        } else {
							$balItem = app.balloon.showErrorByCode(
								$("label[for=" + arr[x].id + "]")[0],
								'_Check_Least_One_Item'
							);
                        }
                    }
                }
            } else if (($item.closest('.udu__item.hidden').length === 0) && (!$item.val() || !String($item.val()).replace(/ /g, ''))) {
				$balItem = app.forms.markAsInvalid(
                    $item,
                    '_You_Did_Not_Fill_Input',
                    !$balItem
                );
            } else if ($item.attr('id') === 'card[expiryMonth]' && Number($item.val()) === 0) {
				$balItem = app.forms.markAsInvalid(
					$item,
					'_You_Did_Not_Fill_Input',
					!$balItem
				);
            } else if ($item.attr('id') === 'card[expiryYear]' && Number($item.val()) === 0) {
				$balItem = app.forms.markAsInvalid(
					$item,
					'_You_Did_Not_Fill_Input',
					!$balItem
				);
            }
        }

        if ($balItem) {
            app.utils.effects.shakeIt($form.find('.invalid-item'));
            return false;
        }

        arr = $(inForm).find('input.email');
        for (x = 0; x < arr.length; x++) {
            if (!app.forms.checkEmail($(arr[x]).val())) {
				$balItem = app.forms.markAsInvalid(
					arr[x],
                    '_Incorrect_Email',
                    !$balItem
                );
                return false;
            }
        }

        // check file item
        var allowedExt = "pdf,jpg,jpeg,png,gif,eps,ai,psd,tif,svg";
        arr = $(inForm).find('input[type=file]');
        for (x = 0; x < arr.length; x++) {
            var file = $(arr[x]).val();
            if (!file) {
                continue
            }
            var ext = ',' + file.split('.').pop() + ',';
            if (String(',' + allowedExt + ',').indexOf(ext) < 0) {
                $balItem = app.balloon.showErrorByCode(
                    arr[x],
                    '_Upload_Incorrect_File',
                    {
                        replace: {
                            '%ext%': allowedExt.replace(/,/g, ', ')
                        }
                    }
                );
                return false;
            }
        }

        var saveDataId = $form.data('form-save-id');

		if (saveDataId) {
			app.setLData(saveDataId, $form.serializeArray());
		}

        return true;
    },


	/**
     * Remove Error Message
	 * @param selector
	 * @returns {jQuery}
	 */
	markAsNotInvalid: function(selector) {
		var $item = $(selector).removeClass('invalid-item');
		app.balloon.close();
		return $item;
    },

	/**
     * Marks Items as Invalid
     *
	 * @param {string|Object} selector
	 * @param {string} text
	 * @param {bool} displayNow
	 * @returns {boolean}
	 */
	markAsInvalid: function(selector, text, displayNow) {
		var $item = $(selector);
		if ($item.length === 0) {
			return false;
		}
		if ($item.data('udu-error-msg') === text && $item.hasClass('invalid-item')) {
		    return false;
        }

		if (!$item.hasClass('udu__er-init')) {
			$item.addClass('udu__er-init');
			$item.focus(
				function () {
					var $this = $(this);
					var err = $this.data('udu-error-msg');
					if ($this.hasClass('invalid-item') && err) {
					    app.balloon.close();
						app.balloon.showError($this, app.getMsg(err));
					}
				}
			).blur(
				function() {
					var $this = $(this);
					app.balloon.closeById($this.data('udu-balloon-id'))
				}
			)
		}

		$item.data('udu-error-msg', text);
		if (text) {
			$item.addClass('invalid-item');
			$item.closest('tr').addClass('udu__tr-invalid-item');
			app.utils.effects.shakeIt($item);
			if (displayNow) {
				app.balloon.showError($item, app.getMsg(text));
			}
		} else {
			$item.removeClass('invalid-item');
			$item.closest('tr').removeClass('udu__tr-invalid-item');
		}

		return $item;
	},

	/**
     * Parse HTML and returns Errors
     *
	 * @param {string} html
	 * @returns {{}}
	 */
	errorsFromHtml: function(html) {
	    var id = app.guid();
		$('#' + id).remove();
		html = '<div id="' + id + '" class="hidden">' + html + '</div>';
		$('body').append(html);
		var res = {};
		var errText = null;
		var $wrapper = $('#' + id);
		$wrapper.find('tr').each(
			function() {
				var $tr = $(this);
				var $errDiv = $tr.find('td.error div');
				if ($errDiv.length === 1) {
					errText = $errDiv.html();
					return true;
				}

				if (!errText) {
					return true;
				}

				var $inp = $tr.find('input, select');
				if ($inp.attr('name')) {
					res[$inp.attr('name')] = errText;
					errText = null;
					return true;
				}
			}
		);
		$wrapper.remove();
		return res;
	},
    _assignClassToTr: function(item) {
		var $this = $(item);
		if ($this.val()) {
			$this.closest('tr').addClass('udu__tr-with-filled-item');
		} else {
			$this.closest('tr').removeClass('udu__tr-with-filled-item');
		}
    },
    initInputs: function() {
		$('input:not(.f_done), select:not(.f_done), textarea:not(.f_done)')
			.addClass('f_done')
            .each(
                function() {
					app.forms._assignClassToTr(this);
                }
            )
            .focus(
                function() {
					var $this = $(this);
					$this.closest('tr').addClass('udu__tr-with-focused-item');
                }
            )
            .blur(
				function() {
					var $this = $(this);
					$this.closest('tr').removeClass('udu__tr-with-focused-item');
					app.forms._assignClassToTr(this);
				}
            )
            .keyup(
				function() {
					app.forms._assignClassToTr(this);
				}
            )
            .change(
				function() {
					app.forms._assignClassToTr(this);
				}
            )
    },
	setDefaultProdOptionsValue: function() {
		if (app.prodInfo && app.prodInfo.cartId) {
			return false;
		}
		if (app.prodExtData) {
		    if (app.prodExtData.prodOptionsDefaultValues) {
				for (var i in app.prodExtData.prodOptionsDefaultValues) {
					var $el = $('.product-options').find('select[data-sys-name="' + i + '"], input[data-sys-name="' + i + '"]');
					if ($el.length === 1) {
						var defVal = app.prodExtData.prodOptionsDefaultValues[i];
						if ($el.attr('type') === 'checkbox' && ($el.data('elem-sys-name') === defVal || defVal === 'Y')) {
							$el.prop('checked', true);
						} else if ($el[0].tagName === 'SELECT') {
							$el.find('option[data-sys-name="' + defVal + '"]').attr('selected', true);
						}
					}
				}
            }
			if (app.prodExtData.inactiveItems) {
				for (i in app.prodExtData.inactiveItems) {
					$el = $('.product-options').find('select[data-sys-name="' + i + '"], input[data-sys-name="' + i + '"]');
					if ($el.length === 1) {
                        $el.closest('li').addClass('udu__inactive')
					}
				}
            }

		}
	},
};