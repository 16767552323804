app.utils.unlinkArr = function(arr) {
	return JSON.parse(JSON.stringify(arr));
};

app.utils.isItemVisible = function(elm, yDelta, evalType) {
	evalType = evalType || "visible";
	yDelta = yDelta || 0;
	var vpH = $(window).height(), // Viewport Height
		st = $(window).scrollTop(), // Scroll Top
		y = $(elm).offset().top,
		elementHeight = $(elm).height();

	if (evalType === "visible") {
		return (y < (vpH + st)) && (y - yDelta > (st - elementHeight));
	}
	if (evalType === "above") {
		return (y < (vpH + st));
	}
};
app.utils.html = {
	box: function($item) {
		$item = $($item);
		if (!$item[0]) {
			return {};
		}
		return $item[0].getBoundingClientRect()
	},
	html2dom: function(html) {
		html = '<div class="1b50ac41-7941-4828-84b3-3145070e4a75">' + html + '</div>';
		return $($.parseHTML(html, document, true));
	},
	removeScripts: function(html) {
		if (typeof html === 'object') {
			return html;
		}
		html = String(html).replace(/<script/g, '');
		return html;
	},
	getUduBody: function(html, suffix) {
		return app.utils.getUduBody(html, suffix)
	}
};

app.utils.getUduBody = function(str, uduAddKey) {
	if (typeof str === 'object') {
		return str;
	}
	var delim = app._vars.uduDelim;

	if (uduAddKey) {
		delim = delim.replace('-->', '_' + uduAddKey + '-->');
	}

	if (str && str.indexOf('<!--ADM-V2-0c6e6edf6a3c7e4823bd2af9dc9c5feb-20250131-->') > 0) {
		str = str.split('<!--ADM-V2-0c6e6edf6a3c7e4823bd2af9dc9c5feb-20250131-->')[1];

		if (!str || str.indexOf(delim) < 0) {
			return str;
		}

		return str.split(delim)[1];
	}

	if (!str || str.indexOf(delim) < 0) {
		return null;
	}
	return str.split(delim)[1];
};

app.utils.file = {
	ext: function(fileName) {
		if (!fileName) {
			return '';
		}
		return fileName.split('.').pop().toLowerCase();
	},
	baseName: function(fileName) {
		if (!fileName) {
			return '';
		}
		return String(fileName).replace(/\\/g, '/').split('/').pop();
	},
	getPageUrlByName: function(fileName) {
		return app.url.ajaxUrlByName(fileName);
	}
};

app.ie = {
	ieIsOld: function() {
		return document.all || window.msCrypto;
	},
	showOldBrowserError: function() {
		if (app.ie.ieIsOld() && app.getLData('showOldBrowserError') !== 'N') {
			app.alert(
				'This website does not fully support Internet Explorer 11 (and earlier) versions. Please upgrade to Microsoft Edge or an equivalent modern web browser.',
				function() {
					app.setLData('showOldBrowserError', 'N')
				}
			);
		}
	}
};

$(
	function() {
		if (app._vars.dispOldBrowserMessage !== 'N') {
			app.ie.showOldBrowserError();
		}
	}
);

app.utils.afterLogin = {
	checkAfterLoginMessage: function() {
		app.getInfo(
			'get-after-login-message',
			function(data) {
				if (data) {
					app.alert(
						data,
						function () {
							app.getInfo(
								'set-after-login-message-done',
								function() {

								}
							)
						},
						{
							cssClass: 'fullMode udu__after-login-popup'
						}
					)
				}
			}
		)
	}
};


/**
 * Initialize messages to be used in system
 *
 * @param {string} inMessages
 * @param {function} callBack
 * @returns {void}
 */
app.initializeMessages = function(inMessages, callBack, lang) {
	lang = lang || app.getLang();
	var that = this;
	var wrd = '';
	var i = 0;
	var savedMessages = this.getLData('savedLangMessages_' + lang, true);
	if (!savedMessages) {
		savedMessages = {};
	}
	if (window.admin) {
		savedMessages = {};
	}
	if (inMessages) {
		if (!Array.isArray(inMessages)) {
			var tmp = inMessages.split(',');
		} else {
			tmp = inMessages;
		}

		for (i = 0; i < tmp.length; i++) {
			if (tmp[i]) {
				if (lang === app.getLang()) {
					that.messages[tmp[i]] = null;
				} else {
					if (!savedMessages[tmp[i]]) {
						wrd += tmp[i] + ',';
					}

				}
			}
		}
	}
	if (!savedMessages) {
		savedMessages = {};
	} else if (lang === app.getLang()) {
		for (i in savedMessages) {
			that.messages[i] = savedMessages[i];
		}
		for (i in that.messages) {
			if (!that.messages[i]) {
				wrd += i + ',';
			}
		}
	}

	wrd = wrd.slice(0, -1);
	if (wrd) {
		$.get(
			app.lang.getUrl(app.url.ajaxUrl(), lang),
			{
				op: 'read-messages',
				wrd: wrd
			},
			function(res) {
				try {
					var data = $.parseJSON(res);
				} catch(e) {
					that.asAjax(true);
					$.get(
						String(document.location),
						{
							op: 'read-messages',
							wrd: wrd
						},
						function(res) {
							that.asAjax(false);
							res = res.split('<!--AJAX_MODE_MSG-->');
							if (res && res[1]) {
								try {
									data = $.parseJSON(res[1]);
									that._savedLangMessages (data, savedMessages, callBack, lang);
								} catch(e) {
									return false;
								}
							}
						}
					);
					return false;
				}

				that._savedLangMessages (data, savedMessages, callBack, lang);
			}
		)
	} else {
		if (callBack) {
			callBack(savedMessages, lang);
		}
	}
};

/**
 * Sets local data for messages
 *
 * @param {{}} data
 * @param {{}} savedMessages
 * @param {function} callBack
 * @private
 */
app._savedLangMessages = function(data, savedMessages, callBack, lang) {
	lang = lang || app.getLang();
	if (data && data.data) {
		for (var i in data.data) {
			var val = this.urlDecode(data.data[i]);
			if (lang === app.getLang()) {
				this.messages[i] = savedMessages[i] = val;
			} else {
				savedMessages[i] = val
			}
		}
	}
	this.setLData('savedLangMessages_' + lang, savedMessages, true);
	if (callBack) {
		callBack(savedMessages, lang);
	}
};


app.cookies = {
	_vars: {

	},
	/**
	 * Creates Cookies for template
	 *
	 * @param {string} name
	 * @param {string} value
	 * @param {number} days
	 * @returns {app.cookies}
	 */
	createCookie: function(name, value, days) {
		var expires = "";
		if (days) {
			var date = new Date();
			date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
			expires = "; expires=" + date.toUTCString();
		}
		if (app.url.isHttps()) {
			document.cookie = name + "=" + value + expires + "; path=/;SameSite=None;secure";
		} else {
			document.cookie = name + "=" + value + expires + "; path=/";
		}
		return this;
	},
	/**
	 * Reads cookie
	 *
	 * @param {string} name
	 * @returns {string}
	 */
	readCookie: function(name) {
		var matches = document.cookie.match(new RegExp(
			"(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
		));
		return matches ? decodeURIComponent(matches[1]) : undefined;
	},
	isActive: function() {
		app.cookies.createCookie('udu_CookieActivated', 'Y');
		var res = app.cookies.readCookie('udu_CookieActivated');
		app.cookies.deleteCookie('udu_CookieActivated');
		return res === 'Y';
	},
	/**
	 * Deletes cookie by name
	 * @param {string} name
	 * @returns {app}
	 */
	deleteCookie: function(name) {
		if (!name) {
			return false;
		}
		document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
		return app;
	}
};