    "use strict";
app.url = {
    _vars: {
        v: '1.0.5'
    },
    ajaxUrlByName:function(fileName) {
        var url = app.url.ajaxUrl();
        return String(url).replace('/ajax-process.html', '/' + fileName)
    },
    ajaxUrl: function(lang) {
        var url = app.ajaxProcessURLs.process;
        if (url === '/pages/ajax-process.html' && app._currentLang && app._vars._currentCurrency) {
            url = '/' + app._currentLang + '/' + app._vars._currentCurrency + url;
        } else if (url === '/pages/ajax-process.html' && app.ajaxProcessURLs.restURL) {
            var tmp = app.ajaxProcessURLs.restURL.split('/');
            var tp = 'T';
            while (tmp.length > 0) {
                var val = tmp.pop();
                if (val === 'rest') {
                    tp = 'c';
                } else if (tp === 'c') {
                    if (val === '1') {
                        url = '/cad/' + url;
                    } else {
                        url = '/usd/' + url;
                    }
                    tp = 'l';
                } else if (tp === 'l') {
                    if (val === '1') {
                        url = '/en/' + url;
                    } else {
                        url = '/fr/' + url;
                    }
                    break;
                }
            }
            url = app.url.fixUrl(url, true);
        }
        if (lang && lang !== app.getLang()) {
            url = url.replace('/' + app.getLang() + '/', '/' + lang + '/');
        }
        return url;
    },
    titleByUrl: function(url) {
		var pages = app.getLData('uduUrl2Title');
		if (pages[url]) {
		    return pages[url];
        }
        return '';
    },
	getProdIdByUrl: function(href) {
        if (Number(href)) {
            return href;
        }
		var num = String(href).match(/(\d+)\.html/);
		if (!num) {
			return null;
		}
		return Number(num[1]);
	},
    reload: function(lang, cur) {
        if (!lang && !cur) {
            app.url.goTo(app.url.clear(String(document.location), true), true);
            return this;
        }
        var url = document.location.href.split('#')[0] + '/';
        var curLan = app.getLang();
        var curCur = app.money.getCurCurrency();

        if (url.indexOf('/' + curLan + '/') < 0) {
            url += '/' + curLan + '/' + curCur + '/';
        }

        if (arguments.length > 0) {
            for (var i = 0; i < arguments.length; i++) {
                var tmp = arguments[i];
				if (tmp === 'en') {
					url = url.replace('/fr/', '/en/');
				} else if (tmp === 'fr') {
					url = url.replace('/en/', '/fr/');
				} else if (tmp === 'usd') {
					url = url.replace('/cad/', '/usd/');
				} else if (tmp === 'cad') {
					url = url.replace('/usd/', '/cad/');
				}
            }
        }
        url = url.replace(/\/+/g, '/').slice(0, -1).replace(':/', '://');
        app.url.goTo(url, true);
    },
    getAll: function(url) {
        if (!url) {
            url = String(document.location);
        }
        url = url.split('?');
        if (!url[1]) {
            return {};
        }
        var qs = url[1].replace(/&+/g, '&').split('+').join(' ');
        var params = [],
            tokens,
            re = /[?&]?([^=]+)=([^&]*)/g;
        while (tokens = re.exec(qs)) {
            params.push({name: decodeURIComponent(tokens[1]), val: decodeURIComponent(tokens[2])});
        }
        return params;
    },
    isProd: function() {
        var hostName = String(window.location.hostname);
        var domains = {
            'preprod-be.uducat.com': true,
            'manage.promobullitstores.com': true,
            'manage.estoreplatform.com': true,
            'devsecure10.uducat.com': true
        };
        for (var i in domains) {
            if (hostName.indexOf(i) >= 0) {
                return false;
            }
        }
        return true;
    },
    fixUrl: function(url, strong) {
        if (!url) {
            url = String(document.location);
        }
        var params = app.url.getAll(url);
        url = app.url.clear(url);
        var newParams = [];
        var uniq = {};
        if (params) {
            for (var i = 0; i < params.length; i++) {
                var nm = params[i].name;
                var val = params[i].val;
                if (val !== '' && (!uniq[nm] || String(nm).indexOf('[') > 0)) {
                    newParams.push(params[i]);
                    uniq[nm] = '1';
                }
            }
        }
        url = app.url.formURL(url, newParams);
        if (strong) {
            url = url.replace(/\/+/g, '/').slice(0, -1).replace(':/', '://');
        }
        return url;
    },
    clear: function(url, softClear) {
        if (!url) {
            url = document.location;
        }
        url = String(url).split('#')[0];
        if (softClear) {
            return url;
        }
        return url.split('?')[0];
    },
    sortBy: function (item) {
        var url = app.url.setParams({pr_s: app.url.get('pr_s', $(item).val())});
        app.url.goTo(url, true);
	},
    goTo: function(url, blockWnd, delay) {
        if (blockWnd) {
            app.blockWindow(
                true,
                true,
                function() {
                    if (!delay) {
                        document.location = url;
                    } else {
                        setTimeout(
                            function() {
                                document.location = url;
                            },
                            delay
                        )
                    }
                }
            );
            app.utils.effects.showIt('.block-wait');
            $('#div-block').addClass('udu__block-dark');
        } else {
            app.utils.effects.showIt('.block-wait');
            document.location = url;
        }
    },
    goPaymentMethods: function() {
        app.blockWindow(
            true,
            true,
            function() {
                app.getURL(
                    'checkout/payment-methods',
                    function(url) {
                        app.url.goTo(url);
                    }
                )
            }
        );
    },
    goLogOut: function() {
        app.blockWindow(
            true,
            true,
            function() {
                app.getURL(
                    'user/log-out',
                    function(url) {
                        app.url.goTo(url);
                    }
                )
            }
        );
    },
    goLogIn: function() {
        app.blockWindow(
            true,
            true,
            function() {
                app.getURL(
                    'user/log-in',
                    function(url) {
                        app.url.goTo(url);
                    }
                )
            }
        );
    },
    goToSearch: function() {
        app.blockWindow(
            true,
            true,
            function() {
                app.getURL(
                    'search',
                    function(url) {
                        app.url.goTo(url);
                    }
                )
            }
        );
    },
    goCart: function() {
        app.blockWindow(
            true,
            true,
            function() {
                app.getURL(
                    'cart',
                    function(url) {
                        app.url.goTo(url);
                    }
                )
            }
        );
    },
    goHome: function() {
        app.blockWindow(
            true,
            true,
            function() {
                app.getURL(
                    null,
                    function(url) {
                        app.url.goTo(url);
                    }
                )
            }
        );
    },
    getAllKeyed: function(url) {
        var params = app.url.getAll(url);
        var res = {};
        for (var i = 0; i < params.length; i++) {
            res[params[i].name] = params[i].val;
        }
        return res;
    },
    get: function(name, url) {
        var params = app.url.getAll(url);
        for (var i = 0; i < params.length; i++) {
            if (params[i].name === name) {
                return params[i].val;
            }
        }
        return '';
    },
    formURL: function (url, params) {
        if (typeof url === 'object') {
            url = String(url);
        }
        var token = url.indexOf('?') > 0 ? '&' : '?';
        if (Array.isArray(params)) {
            for (var i = 0; i < params.length; i++) {
                if (params[i].val === null) {
                    continue;
                }
                url += token + params[i].name + '=' + encodeURIComponent(params[i].val);
                token = '&';
            }
        } else {
            for (i in params) {
                if (params[i] === null) {
                    continue;
                }
                url += token + i + '=' + encodeURIComponent(params[i]);
                token = '&';
            }
        }
        return url;
    },
    mergeParams: function(arr1, arr2) {
        var pUniq = {};
        var res = [];
        for (var i = 0; i < arr2.length; i++) {
            pUniq[arr2[i].name] = true;
        }

        for (i = 0; i < arr1.length; i++) {
            if (pUniq[arr1[i].name]) {
                continue;
            }
            res.push(arr1[i]);
        }
        for (i = 0; i < arr2.length; i++) {
            res.push(arr2[i]);
        }

        return res;
    },
    setParams: function(params, url) {
        if (!url) {
            url = String(document.location);
        }
        url = app.url.clear(url, true);
        if (!Array.isArray(params)) {
            var tmp = [];
            for (var i in params) {
                tmp.push({name: i, val: params[i]})
            }
			params = tmp;
        }
        var get = app.url.getAll(url);
        var res = app.url.mergeParams(get, params);
        return this.formURL(app.url.clear(url), res);
    },
    getAfterLoginRedirectURL: function() {
        var urls = app.getLData('udu__l-urls', true);
        if (urls) {
            var tmpUrl = app.getLData('redirectAfterLoginUrl');
            if (urls.checkout && urls.checkout === tmpUrl) {
                return tmpUrl;
            }
        }
        try {
            return app.getLData('udu-all-json-local-storage').udu_logInRedirectPage;
        } catch(e) {
            return '';
        }
    },
    setLoginRedirectURL: function(url) {
        var tmpData = app.getLData('udu-all-json-local-storage');
        if (!tmpData) {
            tmpData = {};
        }
        tmpData['udu_logInRedirectPage'] = url;
        app.setLData('udu-all-json-local-storage', tmpData);

        tmpData = app.getLData('get-all-json', true);
        if (tmpData && tmpData.udu__localStorage) {
            tmpData.udu__localStorage.udu_logInRedirectPage = url;
            app.setLData('get-all-json', tmpData, true);
        }
    },
    needToRedirectAfterLogin: function() {
        return app._usrLogged && app.getLData('udu-userWRAL') !== 'Y' && app.url.getAfterLoginRedirectURL();
    },
    doAfterLoginRedirect: function () {
        if (!app._usrLogged) {
            app.setLData('udu-userWRAL', 'N');
        }
        var url = app.url.getAfterLoginRedirectURL();
        if (app._usrLogged && app.getLData('udu-userWRAL') !== 'Y' && url) {
            app.setLData('udu-userWRAL', 'Y');
            if (url) {
				app.setLData('redirectAfterLoginUrl', '');
                app.blockWindow(true);
                app.url.goTo(url);
                return true;
            }
        }
        return false;
    },
    getRefDomain: function() {
        return app.url.getDomain(document.referrer);
    },
    getDomain: function(url) {
        if (!url) {
            url = String(document.location);
        }
        var l = document.createElement("a");
        l.href = url;
        var domain = l.hostname;
        $(l).remove();
        return domain;
    },
    isHttps: function() {
        return String(document.location.protocol).indexOf('https') >= 0;
    },
    getImgFolder: function() {
		return app.getCSSDir().slice(0, -4) + '/img';
    },
	getUrls: function(cb) {
		app.getInfo(
			'get-urls',
			{},
			function(data) {
				if (cb) {
					cb(data);
				}
			},
			'udu__get-urls-' + app.getLang() + '_' + app.money.getCurCurrency()
		);
	},
	getRestUrl: function(callBack) {
        var k = 'uduRestURL-' + app.getLang() + '_' + app.money.getCurCurrency();
		if (!callBack) {
			callBack = function(url) {

			}
		}
		if (app.url._vars[k]) {
			callBack(app.url._vars[k]);
			return true;
		}

		var restUrl = app.getLData(k, 5);
		if (restUrl) {
			app.url._vars[k] = restUrl;
			callBack(restUrl);
			return true;
		}
		app.url.getUrls(
			function(data) {
				var restUrl = JSON.parse(data).restURL;
				app.url._vars[k] = restUrl;
				app.setLData(k, restUrl, 5);
				callBack(restUrl);
			}
		)
	},
    fixBaseUrlOnPage: function() {
        var urls = $('a[href*="{baseUrl}"], a[href*="%7BbaseUrl%7D"],a[href*="{domain}"], a[href*="%7Bdomain%7D"]');
        app.getAllJSON(
            function(data) {
                urls.each(
                    function() {
                        var $this = $(this);
                        if ($this.closest('.wnd__window-container').length === 0) {
                            $this.attr('href', app.url.fixBaseUrl($this.attr('href')));
                        }
                    }
                )
            }
        )
    },
    fixBaseUrl: function(href) {
        href = String(href);
        var baseURL = app.url.baseURL();
        if (baseURL !== null) {
            href = href.replace(/{baseUrl}\/|%7BbaseUrl%7D\//g, baseURL + '/');
            href = href.replace(/{baseUrl}|%7BbaseUrl%7D/g, baseURL + '/');
        }
        href = href.replace(/{domain}|%7Bdomain%7D/g, app.url.getDomain());
        return href;
    },
    baseURL: function() {
        var data = app.getLData('udu-all-json-local-storage');
        if (!data) {
            data = app.getAllJSON();
        }
        if (!data || !data.baseURL) {
            return null;
        }
        return data.baseURL;
    }
};
if (!app.url.isProd()) {
    $('html').addClass('udu__store-dev-mode');
}
